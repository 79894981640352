export default {
  auth: {
    register: {
      method: 'post',
      url: 'app/userreg/register'
    },
    login: {
      method: 'post',
      url: 'app/userlogin/pwdlogin'
    },
    logout: {
      method: 'post',
      url: 'app/logout',
      authorized: true
    },
    getCaptchaBeforeSmsLogin: {
      method: 'post',
      url: 'app/userlogin/sendsms'
    },
    getCaptchaBeforeRegister: {
      method: 'post',
      url: 'app/userreg/sendsms'
    },
    getCaptchaBeforeForgetPwd: {
      method: 'post',
      url: 'app/userforget/sendsms'
    },
    smsLogin: {
      method: 'post',
      url: 'app/userlogin/smslogin'
    },
    resetPassword: {
      method: 'post',
      url: 'app/userforget/setpassword'
    },
    setPassword: {
      method: 'post',
      url: 'app/me/changepwd',
      authorized: true
    },
    getCaptchaBeforeSetPhone: {
      method: 'post',
      url: 'app/me/changemobilesendsms',
      authorized: true
    },
    setPhone: {
      method: 'post',
      url: 'app/me/changemobile',
      authorized: true
    }
  },
  index: {
    getAd: {
      method: 'get',
      url: 'app/index/advert',
      authorized: true
    },
    getRecommend: {
      method: 'get',
      url: 'app/index/recommend',
      authorized: true
    },
    getNews: {
      method: 'get',
      url: 'app/index/artActivityInfos',
      authorized: true
    }
  },
  biz: {
    getArtList: {
      method: 'get',
      url: 'app/artNews/list'
    },
    getTopicList: {
      method: 'get',
      url: 'app/specialNews/list'
    },
    getCooperationList: {
      method: 'get',
      url: 'app/cooperationNews/list'
    },
    getArtInfo: {
      method: 'get',
      url: 'app/artNews/infos'
    },
    getTopicInfo: {
      method: 'get',
      url: 'app/specialNews/infos'
    },
    getCooperationInfo: {
      method: 'get',
      url: 'app/cooperationNews/infos'
    }
  },
  art: {
    getArt: {
      method: 'get',
      url: 'app/opus/infos',
      authorized: true // ?
    },
    getList: {
      method: 'get',
      url: 'app/opus/list',
      authorized: true // ?
    },
    searchArt: {
      method: 'get',
      url: 'app/opus/opussearch',
      authorized: true // ?
    },
    searchArtist: {
      method: 'get',
      url: 'app/opus/artusersearch',
      authorized: true // ?
    }
  },
  user: {
    getUser: {
      method: 'get',
      url: 'app/me/userinfo',
      authorized: true
    },
    updateUser: {
      method: 'post',
      url: 'app/me/updateuserinfo',
      authorized: true
    },
    getMyArtList: {
      method: 'get',
      url: 'app/me/myopus',
      authorized: true
    },
    getMessage: {
      method: 'get',
      url: 'app/me/messagelist',
      authorized: true
    },
    getInfoByUserId: {
      method: 'get',
      url: 'app/artuser/infos',
      authorized: true
    },
    getArtListByUserId: {
      method: 'get',
      url: 'app/artuser/opus',
      authorized: true
    },
    getResumeByUserId: {
      method: 'get',
      url: 'app/artuser/personalDetails',
      authorized: true
    },
    addToFavorite: {
      method: 'post',
      url: 'app/collection/add',
      authorized: true
    },
    removeFromFavorite: {
      method: 'post',
      url: 'app/collection/remove',
      authorized: true
    },
    getMyFavorite: {
      method: 'get',
      url: 'app/me/myCollction',
      authorized: true
    },
    uploadOpus: {
      method: 'post',
      url: 'app/me/uploadOpus',
      authorized: true
    },
    getUploadOpus: {
      method: 'get',
      url: 'app/opus/infosForEditor',
      authorized: true
    },
    auth: {
      method: 'post',
      url: 'app/me/authenticate',
      authorized: true
    },
    getAuth: {
      method: 'get',
      url: 'app/me/myauthenticate',
      authorized: true
    }
  },
  cart: {
    addToCart: {
      method: 'post',
      url: 'app/share/add',
      authorized: true
    },
    removeFromCart: {
      method: 'post',
      url: 'app/share/remove',
      authorized: true
    },
    getMyCart: {
      method: 'get',
      url: 'app/share/mylist',
      authorized: true
    },
    getCartId: {
      method: 'post',
      url: 'app/share/out',
      authorized: true
    },
    getCartById: {
      method: 'get',
      url: 'app/share/userlist'
    },
    order: {
      method: 'post',
      url: 'app/share/makeorder',
      authorized: true
    }
  },
  oss: {
    getOssKey: {
      method: 'get',
      url: 'app/common/osskey'
    },
    getAvatarOssKey: {
      method: 'get',
      url: 'app/common/osskey/avatar',
      authorized: true
    },
    getOpusOssKey: {
      method: 'get',
      url: 'app/common/osskey/opus',
      authorized: true
    },
    getFeedbackOssKey: {
      method: 'get',
      url: 'app/common/osskey/feedback',
      authorized: true
    },
    getCertOssKey: {
      method: 'get',
      url: 'app/common/osskey/usercertificate',
      authorized: true
    },
    getBgOssKey: {
      method: 'get',
      url: 'app/common/osskey/background',
      authorized: true
    }
  },
  contract: {
    getContractList: {
      method: 'get',
      url: 'app/shareCarOrders/list',
      authorized: true
    },
    getContract: {
      method: 'get',
      url: 'app/shareCarOrders/get',
      authorized: true
    }
  },
  others: {
    getArgeement: {
      method: 'get',
      url: 'app/aggrement'
    },
    getTags: {
      method: 'get',
      url: 'app/opus/tagList',
      authorized: true // ?
    },
    getQr: {
      method: 'get',
      url: 'app/qr'
    }
  }
}
