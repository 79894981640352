<script>
export default {
  functional: true,
  render (h, {
    props: { style },
    data: { staticStyle }
  }) {
    const Image = h('van-image', {
      props: {
        height: 90,
        width: 66,
        src: '/static/image/byzx_pure.png',
        fit: 'contain',
        title: '不一致选',
        showLoading: false
      }
    })

    return h('center', {
      style: style || staticStyle,
    }, [Image])
  }
}
</script>
