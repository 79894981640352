import Layouts from '@/layouts'

import store from '@/store'
import utils from '@/utils'

import { requestMap as bizRequestMap } from '@/views/biz/tab-components'
import { LoginComponentKeys, defaultLoginComponentKey } from '@/views/login/Index'

export const constantRouterMap = [
  {
    path: '/login',
    component: Layouts.LoginLayout,
    children: [
      {
        path: '',
        redirect: `/login/${defaultLoginComponentKey}`
      },
      {
        path: ':componentId',
        beforeEnter (to, from, next) {
          if (store.getters.logined) {
            return next({ name: 'index' })
          }

          if (LoginComponentKeys.indexOf(to.params.componentId) === -1) {
            return next({
              path: `/login/${defaultLoginComponentKey}`
            })
          }

          next()
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Index')
      }
    ]
  },
  {
    path: '/cart/:id?',
    name: 'cart',
    beforeEnter: (to, from, next) => {
      if (to.params.id === undefined) {
        // TODO: id === undefined means my cart
      }

      if (to.query.redirect === undefined) {
        const $to = utils.cloneDeep(to)
        const fromPath = from ? from.fullPath : '/'

        $to.query.redirect = window.encodeURIComponent(fromPath)

        return next($to)
      }

      next()
    },
    component: () => import(/* webpackChunkName: "cart" */ '@/views/cart/Id')
  },
  {
    path: '/',
    component: Layouts.BasicLayout,
    children: [
      {
        path: '',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '@/views/Index')
      },
      // {
      //   path: '/indexNew',
      //   name: 'indexNew',
      //   component: () => import(/* webpackChunkName: "index" */ '@/views/IndexNew')
      // },

      {
        path: 'userupload',
        name: 'userupload',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Uploaded')
      },
      {
        path: 'biz-list',
        name: 'biz-list',
        component: () => import(/* webpackChunkName: "biz" */ '@/views/biz/Index')
      },
      {
        path: 'biz/:type/:id',
        name: 'biz',
        beforeEnter: (to, from, next) => {
          const {
            type,
            id
          } = to.params

          if (Object.keys(bizRequestMap).indexOf(type) === -1 || id === undefined) {
            return next(false)
          }

          next()
        },
        component: () => import(/* webpackChunkName: "biz" */ '@/views/biz/Id')
      },
      {
        path: 'art',
        name: 'art-list',
        component: () => import(/* webpackChunkName: "art" */ '@/views/art/Index')
      },
      {
        path: 'art/:id',
        name: 'art',
        component: () => import(/* webpackChunkName: "art" */ '@/views/art/Id')
      },
      {
        path: 'user',
        component: { render (h) { return h('router-view') } },
        meta: {
          authorized: true
        },
        children: [
          {
            // '' => '/user' polyfill
            path: '/user',
            name: 'user',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/Index')
          },
          {
            path: 'about',
            name: 'about',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/About')
          },
          {
            path: 'authorize',
            name: 'authorize',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/authorize')
          },
          {
            path: 'feedback',
            name: 'feedback',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/Feedback')
          },
          {
            path: 'message',
            name: 'message',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/Message')
          },
          {
            path: 'setting',
            name: 'setting',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/setting/Index')
          },
          {
            path: 'setting/phone',
            name: 'setting-phone',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/setting/Phone')
          },
          {
            path: 'setting/password',
            name: 'setting-password',
            component: () => import(/* webpackChunkName: "user" */ '@/views/user/setting/Password')
          },
          {
            path: ':id',
            component: { render (h) { return h('router-view') } },
            beforeEnter: (to, from, next) => {
              return store.dispatch('GetInfo', ({ id }) => {
                if (
                  to.params.id !== id &&
                  to.name !== 'user-page' // exclude
                ) {
                  return next({ name: 'user' })
                }

                next()
              })
            },
            children: [
              {
                // '' => '/user/:id' polyfill
                path: '/user/:id',
                name: 'user-page',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/Id')
              },
              {
                path: 'uploaded',
                name: 'uploaded',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Uploaded')
              },
              {
                path: 'uploaded/:opusId',
                name: 'uploadedId',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Uploaded')
              },
              {
                path: 'my-works',
                name: 'my-works',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/MyWorks')
              },
              {
                path: 'favorites',
                name: 'favorites',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Favorites')
              },
              {
                path: 'traded',
                name: 'traded',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Traded')
              },
              {
                path: 'profile',
                name: 'profile',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Profile')
              },
              {
                path: 'resume',
                name: 'resume',
                component: () => import(/* webpackChunkName: "user" */ '@/views/user/id/Resume')
              }
            ]
          },
          {
            path: '*',
            redirect: { name: 'user' }
          }
        ]
      },
      {
        path: '*',
        redirect: { name: 'index' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  },
]