import request from '@/api/request'
import utils from '@/utils'
import pullRefresh from '@/mixins/pull-refresh'

export const requestMap = {
  art: {
    index: 'getArtList',
    id: 'getArtInfo',
    props: {
      width: 120, height: 120
    }
  },
  topic: {
    index: 'getTopicList',
    id: 'getTopicInfo',
    props: {
      width: '100%', height: 170
    }
  },
  cooperation: {
    index: 'getCooperationList',
    id: 'getCooperationInfo',
    props: {
      width: '100%', height: 170
    }
  }
}

export const BizList = {
  data () {
    const type = this.$route.query.type
    const { index, props } = requestMap[type]
    const asyncFetchData = request[index] || (() => Promise.resolve())
    const initialValue = pullRefresh.data()

    return {
      type,
      size: props,
      initialValue,
      asyncFetchData
    }
  },
  mixins: [pullRefresh],
  render (h) {
    if (!this.$parent.isActive) {
      for (let key in this.initialValue) {
        this[key] = this.initialValue[key]
      }

      return
    }

    const list = this.datas.map(({
      id,
      title,
      imgUrl,
      // location,
      createTime
    }) => h('art-card-news', {
      class: [this.type],
      props: {
        src: imgUrl,
        name: title,
        stamp: utils.fromNow(createTime),
        onClick: () => this.$router.push({
          name: 'biz',
          params: { type: this.type, id }
        }),
        ...this.size
      }
    }))

    const wrapper = h('van-list', {
      props: {
        value: this.loading,
        finished: this.finished,
        finishedText: '没有更多了'
      },
      on: {
        input: val => this.loading = val,
        load: evt => this.onLoad(evt)
      }
    }, list)

    return h('a-spin', {
      props: {
        spinning: this.busy
      }
    }, [
      h('van-pull-refresh', {
        props: {
          value: this.refreshing,
          headHeight: 44
        },
        on: {
          input: val => this.refreshing = val,
          refresh: evt => this.onRefresh(evt)
        }
      }, [wrapper])
    ])
  }
}
