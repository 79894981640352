import cloneDeep from 'lodash/cloneDeep'
import trim from 'lodash/trim'
import throttle from 'lodash/throttle'
import kebabCase from 'lodash/kebabCase'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import assign from 'lodash/assign'

export {
  cloneDeep,
  trim,
  throttle,
  kebabCase,
  isEqual,
  isEmpty,
  assign
}
