<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    name: {
      type: String,
      default: 'mobileNumber'
    },
    labelVisible: {
      type: Boolean,
      default: true
    }
  },
  render (h) {
    const inputProps = {
      value: this.value,
      name: this.name,
      type: 'number',
      placeholder: '请输入手机号',
      border: false,
      size: 'large',
      rules: [
        { required: true, message: '请填写手机号' },
        { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确' }
      ]
    }

    if (this.labelVisible) {
      Object.assign(inputProps, {
        label: '+86',
        labelWidth: 40
      })
    }

    const Input = h('van-field', {
      props: inputProps,
      on: {
        input: _value => this.$emit('input', _value)
      }
    })
    const Divider = h('van-divider', {
      style: {
        marginTop: '0',
        marginBottom: '0'
      }
    })

    return h('div', [Input, Divider])
  }
}
</script>
