const modules = require.context('.', true, /\/.*\.vue$/)
const o = {}

modules.keys().forEach(key => {
  const arr = key.split('/')
  const moduleName = arr[1].split('.')[0]

  if (moduleName) {
    o[moduleName] = modules(key).default
  }
})

export default o
