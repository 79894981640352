<script>
export default {
  functional: true,
  render (h, { props: {
    width = 80,
    height = 80,
    src,
    name,
    location,
    number
  }}) {
    const image = h('van-image', {
      props: {
        width,
        height,
        fit: 'cover',
        round: true,
        src: src ? `${src}!avatar` : '',
        errorIcon: 'photo-o',
        lazyLoad: true
      }
    })
    const text = [h('p', { class: ['t'] }, name)]
    const desc = []

    if (location !== undefined) {
      desc.push(
        // h('van-icon', { props: { name: 'location' } }),
        h('span', location)
      )
    }

    if (number > 0) {
      desc.push(
        h('span', number + '件作品')
      )
    }

    if (desc.length) {
      text.push(
        h('p', desc)
      )
    }

    return h('div', {
      class: ['CardArtist']
    }, [
      image,
      h('div', text)
    ])
  }
}
</script>

<style lang="less">
.CardArtist {
  font-size: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;

  .van-image + div {
    padding: 7px 10px;
    text-align: center;
  }

  .t {
    color: @gray-8;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
  }

  span {
    color: #aaa;
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
  }
}
</style>