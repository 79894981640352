<template>
  <div class="Avatar">
    <van-image
      :src="fileList[0] && fileList[0].url"
      :width="width"
      :height="height"
      :show-loading="false"
      :round="round"
    />
    <van-uploader
      :before-read="() => fileList = []"
      :after-read="afterRead"
      v-model="fileList"
      :preview-full-image="false"
      :preview-image="false"
      :deletable="false"
      result-type="file"
    >
      <van-button icon="photograph" />
    </van-uploader>
  </div>
</template>

<script>
import request from '@/api/request'
import utils from '@/utils'

export default {
  data () {
    return {
      fileList: []
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    ossType: {
      type: String,
      default: 'common'
    },
    width: {
      type: [String, Number],
      default: 64
    },
    height: {
      type: [String, Number],
      default: 64
    },
    round: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: 'setFileList'
  },
  methods: {
    setFileList (value) {
      const fileList = [{ url: value }]

      if (!utils.isEqual(fileList, this.fileList)) {
        this.fileList = fileList
      }
    },
    afterRead (file) {
      request.upload(file.file, this.ossType).then((fileUrl) => {
        this.fileList = [{ url: fileUrl }]
        this.$emit('input', fileUrl)
      })
    }
  },
  mounted () {
    if (this.value !== undefined) {
      this.setFileList(this.value)
    }
  }
}
</script>

<style lang="less">
.Avatar {
  position: relative;
  line-height: 0;
  display: inline-block;
  background-color: #f7f8fa;
  border-radius: 8px;
  overflow: hidden;

  img:not([src]) {
    opacity: 0;
  }

  .van-uploader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .van-button {
      border: 0;
      height: 24px;
      background: none;
      color: #fff;

      .van-icon {
        color: #dcdee0;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}
</style>