<template>
  <div class="PosterArtist">
    <div class="wrapper">
      <img class="cover" width="100%" :src="`${imageUrlComputed}?t=${Date.now()}`" crossOrigin="anonymous" />
      <!-- <div :style="{
        backgroundImage: `url('${imageUrlComputed}')`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingTop: '85.33%', // 640 / 750
        height: 0
      }"></div> -->
      <center>
        <img class="round" width="90" height="90" :src="`${avatar}?t=${Date.now()}`" crossOrigin="anonymous" />
        <h3>{{ avatarName }}</h3>
        <p>{{ subTitle }}</p>
      </center>
    </div>
    <div class="footer">
      <img src="/static/image/byzx_text.png" alt="">
      <div class="right">
        <p>识别二维码<br>看Ta的作品</p>
        <span class="frame">
          <img :src="qrUrl" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'avatar',
    'imageUrl',
    'avatarName',
    'subTitle',
    'qrUrl'
  ],
  computed: {
    imageUrlComputed () {
      if (this.imageUrl.indexOf('http') === 0) {
        return this.imageUrl.replace('https://oss.resource','http://share') + '!resume_source'
      }

      return this.imageUrl
    }
  }
}
</script>

<style lang="less">
@logo-width: 124px;
@qr-width: 60px;

.PosterArtist {
  width: 375px;
  background-color: #F7F8FA;
  -webkit-font-smoothing: antialiased;
  position: relative;

  img {
    vertical-align: middle;
    border-style: none;
  }

  .wrapper {
    background-color: #FFF;
    text-align: center;
    position: relative;

    &:after {
      content: '';
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    center {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 9;
    }

    .round {
      border-radius: 500px;
      margin-top: -50px;
      margin-bottom: 15px;
      border: 2px solid #FFF;
    }

    h3 {
      color: #ffffff;
      font-size: 20px;
      margin-bottom: 5px;
      padding: 0 10%;
    }

    p {
      color: #ffffff;
      font-size: 14px;
      margin-bottom: 10px;
      padding: 0 10%;
    }
  }

  .footer {
    padding: 20px;

    & > img {
      margin-left: 10px;
      height: 35px;
    }

    center {
      font-size: 0;
      width: @logo-width;
      display: inline-block;
      vertical-align: middle;
    }

    .right {
      width: calc(100% - @logo-width + 10px);
      display: inline-block;
      vertical-align: middle;
      text-align: right;

      p {
        color: #C9C9C9;
        font-size: 12px;
        line-height: 18px;
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - @qr-width);
        padding-right: 20px;
        margin-bottom: 0;
      }

      .frame {
        width: 60px;
        height: 60px;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>