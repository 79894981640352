<script>
export default {
  data () {
    return {
      pwdVisible: false
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'password'
    }
  },
  render (h) {
    const Input = h('van-field', {
      props: {
        value: this.value,
        name: this.name,
        placeholder: '请输入密码',
        border: false,
        size: 'large',
        type: this.pwdVisible ? 'input' : 'password',
        rightIcon: 'eye-o',
        rules: [
          { required: true, message: '请填写密码' }
        ]
      },
      on: {
        input: _value => this.$emit('input', _value),
        'click-right-icon': () => {
          this.pwdVisible = !this.pwdVisible
        }
      }
    })
    const Divider = h('van-divider', {
      style: {
        marginTop: '0',
        marginBottom: '0'
      }
    })

    return h('div', [Input, Divider])
  }
}
</script>