<template>
  <div class="FileList">
    <van-uploader
      v-model="fileList"
      :max-count="maxCount"
      :after-read="afterRead"
      @delete="onDelete"
      multiple
    />
  </div>
</template>

<script>
import request from '@/api/request'
import utils from '@/utils'
import pLimit from 'p-limit'

const limit = pLimit(2) // make concurrency 2

export default {
  data () {
    return {
      fileList: []
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    ossType: {
      type: String,
      default: 'common'
    },
    maxCount: {
      type: [Number, String],
      default: 1
    }
  },
  watch: {
    value: 'setFileList'
  },
  methods: {
    setFileList (value) {
      const fileList = value.map(url => ({ url }))

      if (!utils.isEqual(fileList, this.fileList)) {
        this.fileList = fileList
      }
    },
    afterRead (fileList) {
      if (!fileList) {
        return
      }

      if (!Array.isArray(fileList)) {
        fileList = [fileList]
      }

      fileList = fileList.slice(0, this.maxCount)

      Promise.all(
        fileList.map(file => {
          return limit(() => request.upload(file.file, this.ossType))
        })
      ).then(fileListUrl => {
        fileList.forEach((file, index) => {
          if (fileListUrl[index]) {
            file.url = fileListUrl[index]
          }
        })

        this.onDelete()
      })
    },
    onDelete () {
      this.$nextTick(() => {
        this.$emit('input', this.fileList.map(({ url }) => url))
      })
    }
  },
  mounted () {
    if (Array.isArray(this.value)) {
      this.setFileList(this.value)
    }
  }
}
</script>