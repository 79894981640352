export default {
  data () {
    return {
      datas: [],
      refreshing: false,
      loading: false,
      finished: false,
      busy: false,
      // pager
      usePager: true,
      startAt: -1,
      page: 1,
      pageSize: 8,
      getParams: () => {},
      inited: false
    }
  },
  methods: {
    onLoad () {
      if (this.refreshing) {
        this.datas = []
        this.refreshing = false
        this.startAt = -1
        this.page = 1
      } else {
        if (this.startAt < 0) {
          this.startAt = Date.now()
        }

        if (!this.busy) {
          const params = this.usePager
            ? {
              currPage: this.page,
              pageSize: this.pageSize,
              queryTime: this.startAt
            }
            : {}

          this.busy = true
          this.asyncFetchData({
            ...params,
            ...this.getParams(),
          }).then(res => {
            if (this.usePager) {
              const {
                totalPage,
                list
              } = res
              this.datas = this.datas.concat(list)
              this.finished = totalPage < (this.page += 1)
            }
            else {
              this.datas = res
              this.finished = true
            }

            this.inited = true
            this.loading = false
            this.busy = false
          })
        }
      }
    },
    onRefresh () {
      this.finished = false
      this.loading = true
      this.onLoad()
    }
  }
}