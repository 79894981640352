<template>
  <div class="field-Captcha">
    <van-field
      v-model="rawValue"
      type="number"
      :rules="[
        { required: true, message: '验证码不可为空' },
        { pattern: /\d{6,8}/, message: '验证码长度为6-8位' }
      ]"
      :name="name"
      :label="label"
      :size="size"
      :border="false"
      placeholder="请输入验证码"
      center
    >
      <template #button>
        <van-button
          size="small"
          type="info"
          native-type="button"
          :plain="timeVisible"
          :disabled="timeVisible"
          @click="start"
        >
          <van-count-down
            v-if="timeVisible"
            :time="time"
            @finish="end"
            format="ss s"
            ref="timer"
          />
          <span v-else>获取验证码</span>
        </van-button>
      </template>
    </van-field>
    <van-divider :style="{ marginTop: '0', marginBottom: '0' }" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      rawValue: '',
      inited: false,
      timeVisible: false,
      // TODO: store in ls
      time: 60 * 1e3
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'code'
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'large'
    },
    autoStart: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (val) {
      if (!this.inited) {
        this.inited = true
        this.rawValue = val
      }
    },
    rawValue (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    start () {
      this.$emit('start', time => {
        this.timeVisible = true
        this.$nextTick(() => {
          const timer = this.$refs.timer

          if (time !== undefined) {
            this.time = time
          }

          if (timer) {
            timer.start()
          }
        })
      })
    },
    end () {
      this.timeVisible = false
      this.$emit('end')
    }
  },
  created () {
    if (this.autoStart) {
      this.$nextTick(() => {
        this.start()
      })
    }
  }
}
</script>

<style lang="less">
.field-Captcha {

  .van-field__button {
    margin-top: -3px;
    margin-bottom: -3px;
  }
}
</style>