import { isWechat } from './ua'

export function openWindow (url) {
  if (isWechat) {
    return window.location.href = url
  }

  const nWin = window.open('', '_blank')

  return nWin.location.href = url
}
