import Vue from 'vue'
import vueRouter from '@/router'
import request, { API_TOKEN } from '@/api/request'
import utils from '@/utils'

import {
  DEFAULT_AVATAR,
  DEFAULT_BACKGROUND
} from '@/config/constant'

const isValidImage = url => {
  if (typeof url !== 'string') {
    return false
  }

  if (url.indexOf('/') === 0) {
    url = window.location.origin + url
  }

  let o

  try {
    o = new URL(url)
  } catch (err) {
    //
  }

  return typeof o === 'object'
}

const user = {
  state: {
    info: {},
    infoExpiredAt: false
  },
  getters: {
    userInfo: state => state.info,
    logined: state => state.info.id !== undefined
  },
  mutations: {
    SET_INFO: (state, info) => {
      if (!isValidImage(info.avatar)) {
        info.avatar = DEFAULT_AVATAR
      }
      if (!isValidImage(info.backgroundUrl)) {
        info.backgroundUrl = DEFAULT_BACKGROUND
      }

      state.info = info
      state.infoExpiredAt = utils.dayjs().add(10, 'minutes')
    }
  },
  actions: {
    $GetInfo ({ commit }, callback) {
      const token = Vue.ls.get(API_TOKEN)

      if (token !== null) {
        return request.getUser().then(({ user }) => {
          if (user) {
            // merge details
            request.getResumeByUserId({
              userId: user.id
            }).then(({ details }) => {
              if (details !== undefined) {
                commit('SET_INFO', Object.assign(user, details))
              }
              if (typeof callback === 'function') {
                callback(user)
              }
            })
          }
        })
      }

      vueRouter.app.$router.push('/login')
    },
    // for art id page
    $$GetInfo ({ commit }, callback) {
      const token = Vue.ls.get(API_TOKEN)

      if (token === null) {
        // if not token stored
      } else {
        // if invalid token
        const { name: to } = vueRouter.app.$route

        if (to === 'art') {
          return
        }

        return request.getUser().then(({ user }) => {
          if (user) {
            // merge detailss
            request.getResumeByUserId({
              userId: user.id
            }).then(({ details }) => {
              if (details !== undefined) {
                commit('SET_INFO', Object.assign(user, details))
              }
              if (typeof callback === 'function') {
                callback(user)
              }
            })
          }
        })
      }
    },
    GetInfo ({ state, getters, dispatch }, callback) {
      if (
        getters.logined &&
        utils.dayjs().isBefore(state.infoExpiredAt)
      ) {
        if (typeof callback === 'function') {
          callback(state.info)
        }

        return
      }

      dispatch('$GetInfo', callback)
    },
    Logout ({ commit }) {
      request.logout().then(() => {
        commit('SET_INFO', {})

        Vue.ls.remove(API_TOKEN)
        vueRouter.app.$router.push('/login')
      })
    }
  }
}

export default user
