<template>
  <div>
    <h1>注册</h1>
    <van-form @submit="onSubmit">
      <art-field-phone v-model="form.mobileNumber" />
      <art-field-captcha
        v-model="form.code"
        @start="getCaptcha"
      />
      <art-field-password v-model="form.password" />
      <van-button type="info" :disabled="!agreed" block>注&nbsp;册</van-button>
    </van-form>
    <agreement v-model="agreed" />
  </div>
</template>

<script>
import Agreement from './Agreement'
import request from '@/api/request'

export default {
  data () {
    return {
      form: {
        mobileNumber: '',
        code: '',
        password: ''
      },
      agreed: false
    }
  },
  methods: {
    onSubmit (values) {
      request.register(values).then(() => {
        this.$notify({
          type: 'success',
          message: '注册成功，请返回登录'
        })
        this.$router.push('/login')
      })
    },
    getCaptcha (start) {
      if (this.form.mobileNumber) {
        request.getCaptchaBeforeRegister({ mobileNumber: this.form.mobileNumber })
          .then(() => start())
      }
    }
  },
  components: { Agreement }
}
</script>
