<script>
import utils from '@/utils'

export default {
  data () {
    return {
      checked: []
    }
  },
  props: {
    value: {
      type: [Object, Array],
      default: () => ({ index: -1, name: null })
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    span: [Number, String],
    gutter: [Number, String]
  },
  render (h) {
    const {
      value,
      options,
      multiple,
      span,
      gutter
    } = this

    let isLayout = span !== undefined
      || gutter !== undefined
    let checked

    if (Array.isArray(value)) {
      checked = value.map(o => {
        if (o.name) {
          return o.index
        }

        return options.indexOf(o)
      })
    } else if (
      typeof value === 'object' &&
      (value.index > 0)
    ) {
      checked = [value.index]
    } else {
      checked = [options.indexOf(value)]
    }

    if (
      checked &&
      !utils.isEqual(checked, this.checked)
    ) {
      this.checked = checked
    }

    let children = options.map((name, index) => {
      let button = h('van-button', {
        props: {
          type: this.checked.indexOf(index) > -1 ? 'info' : 'default',
          nativeType: 'button', // stop submit
          block: isLayout,
          size: 'small'
        },
        on: {
          click: evt => {
            evt.stopPropagation()

            let checked = this.checked.slice()
            const idx = checked.indexOf(index)

            if (multiple) {
              if (idx > -1) {
                checked.splice(idx, 1)
              } else {
                checked.push(index)
              }
            } else {
              if (idx > -1) {
                checked = []
              } else {
                checked = [index]
              }
            }

            this.$emit('input', checked.map(
              _idx => ({
                index: _idx,
                name: options[_idx]
              })
            ))

            this.checked = checked
          }
        }
      }, [name])

      if (isLayout) {
        button = h('van-col', { props: { span } }, [button])
      }

      return button
    })

    if (isLayout) {
      children = [h('van-row', { props: { gutter } }, children)]
    }

    return h('div', {
      class: 'RadioGroup'
    }, children)
  }
}
</script>

<style lang="less">
.RadioGroup {
  .van-row .van-col {
    margin-bottom: 10px;
  }
}
</style>