<template>
  <div>
    <h1>账号密码登录</h1>
    <van-form @submit="onSubmit">
      <art-field-phone v-model="form.username" name="username" :label-visible="false" />
      <art-field-password v-model="form.password" />
      <van-button type="info" block>登录</van-button>
    </van-form>
    <a class="to" @click="$emit('router-push', 'sms-login')">手机号登录</a>
    <a class="to pull-right" @click="$emit('router-push', 'password-reset')">忘记密码？</a>
  </div>
</template>

<script>
import request from '@/api/request'

export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit (values) {
      request.login(values).then(() => {
        const redirect = this.$route.query.redirect

        if (redirect) {
          return this.$router.push(
            window.decodeURIComponent(redirect)
          )
        }

        this.$router.push({ name: 'index' })
      })
    }
  }
}
</script>
