import Vue from 'vue'
import utils from '@/utils'
import {
  OPUS_TYPE
} from '@/config/constant'

Vue.filter('fromNow', function (value) {
  return utils.fromNow(value)
})

Vue.filter('money', function (value, defaultValue) {
  if (typeof value !== 'number') {
    return value || defaultValue
  }

  const [int, point] = String(value).split('.')
  const arr = int.split('')

  const s = []

  while (arr.length > 0) {
    const end3 = arr.splice(-3)

    s.unshift(end3.join(''))
  }

  if (point !== undefined) {
    return `${s.join(',')}.${point}`
  }

  return `${s.join(',')}`
})

Vue.filter('dayjs', function (value, format = 'YYYY-MM-DD HH:mm:ss') {
  const dayjsObj = utils.dayjs(value)

  if (dayjsObj.isValid()) {
    return dayjsObj.format(format)
  }

  return ''
})

Vue.filter('subTitle', function (value) {
  const {
    width = 0,
    height = 0,
    years,
    opusType
  } = value

  const subTitle = [
    OPUS_TYPE[opusType],
    `${width}x${height}cm` // TODO: 0
  ]

  if (years > 0) {
    subTitle.push(`${years}`)
  }

  return subTitle.join(' / ')
})
