<template>
  <div key="s5">
    <h1>重置密码</h1>
    <van-form @submit="onSubmit">
      <art-field-phone name="mobileNumber" v-model="form.mobileNumber" />
      <art-field-captcha
        name="code"
        v-model="form.code"
        @start="getCaptcha"
      />
      <art-field-password name="password" v-model="form.password" />
      <van-button type="info" block>完&nbsp;成</van-button>
    </van-form>
  </div>
</template>

<script>
import request from '@/api/request'

export default {
  data () {
    return {
      form: {
        mobileNumber: '',
        code: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit (values) {
      request.resetPassword(values).then(() => {
        this.$notify({
          type: 'success',
          message: '重置密码成功，请重新登录'
        })
        this.$router.push('/login')
      })
    },
    getCaptcha (start) {
      if (this.form.mobileNumber) {
        request.getCaptchaBeforeForgetPwd({ mobileNumber: this.form.mobileNumber })
          .then(() => start())
      }
    }
  }
}
</script>
