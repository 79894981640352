import filterXSS from 'xss'

const whiteList = filterXSS.getDefaultWhiteList()

// add figure to whiteList
whiteList['figure'] = []
whiteList['figcaption'] = []

function filterHTML(el, { value }) {
  if (el instanceof HTMLElement) {
    el.classList.add('x-filter-html')
    el.innerHTML = filterXSS(value, {
      whiteList
    })
  }
}

export const filterHtml = {
  inserted: filterHTML,
  update: filterHTML
}
