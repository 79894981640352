<script>
import {
  RESUME_GROUP_DELIMITER,
  RESUME_DELIMITER
} from '@/views/user/id/Resume'

export const CloseInput = {
  data () {
    return {
      delimiter: RESUME_DELIMITER
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  render (h) {
    const [f, s] = this.value.split(this.delimiter)
    const fInput = h('van-field', {
      props: {
        value: f,
        placeholder: '展览时间 / 地点',
        rules: [
          { required: true, message: '不可为空' }
        ]
      },
      on: {
        input: _value => this.$emit(
          'input', [_value, s].join(this.delimiter)
        )
      }
    })
    const sInput = h('van-field', {
      props: {
        value: s,
        placeholder: '展览名称',
        type: 'textarea',
        autosize: true,
        rules: [
          { required: true, message: '不可为空' }
        ]
      },
      on: {
        input: _value => this.$emit(
          'input', [f, _value].join(this.delimiter)
        )
      }
    })
    const Input = h('div', { class: 'input' }, [fInput, sInput])
    const Close = h('van-icon', {
      class: ['close'],
      props: { name: 'cross' },
      on: {
        click: () => this.$emit('close')
      }
    })

    return h('div', {
      class: ['closeInput']
    }, [Input, Close])
  }
}

export const CloseInputGroup = {
  data () {
    return {
      delimiter: RESUME_GROUP_DELIMITER
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    maxCount: {
      type: [Number, String],
      default: 12
    }
  },
  methods: {
    add () {
      const rows = this.value.split(this.delimiter)

      if (rows.length < Number(this.maxCount)) {
        this.$emit('input',
          this.value + this.delimiter,
          rows.length + 1
        )
      }
    }
  },
  components: { CloseInput },
  render (h) {
    const childrenValues = this.value.split(this.delimiter)

    return h('div', {
      class: ['closeInputGroup']
    }, childrenValues.map(
      (value, index) => h('close-input', {
        props: {
          value
        },
        on: {
          input: _value => {
            const oValues = childrenValues.slice()

            oValues[index] = _value
            this.$emit('input',
              oValues.join(this.delimiter),
              childrenValues.length
            )
          },
          close: () => {
            const oValues = childrenValues.slice()

            oValues.splice(index, 1)
            this.$emit('input',
              oValues.join(this.delimiter),
              childrenValues.length
            )
          }
        }
      })
    ))
  }
}

export default CloseInputGroup

</script>

<style lang="less">
.closeInputGroup {

  .closeInput {
    display: flex;

    .input {
      flex: 1;

      .van-cell {
        padding: 10px 0;
      }
    }

    .close {
      color: #9E9E9E;
      font-size: 14px;
      margin-left: 15px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background-color: #F7F8FA;
      border-radius: 500px;
      margin-top: 44px - 10px;
    }

    .van-cell:not(:last-child)::after {
      left: 0;
    }
  }
}
</style>