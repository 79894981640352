<template>
  <div class="l-Basic">
    <router-view />
    <van-tabbar route>
      <van-tabbar-item replace :to="{ name: 'index' }">首页
        <template #icon>
          <van-icon class-prefix="icon-artalliance" name="home" size="30" />
        </template>
      </van-tabbar-item>
<!--      <van-tabbar-item replace :to="{ name: routename }">{{tabname}}-->
<!--        <template #icon>-->
<!--          <van-icon class-prefix="icon-artalliance" name="pick" size="30" />-->
<!--        </template>-->
<!--      </van-tabbar-item>-->

      <van-tabbar-item replace :to="{ name: 'biz-list', query: { type: 'art' } }">动态
        <template #icon>
          <van-icon class-prefix="icon-artalliance" name="news" size="30" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  v-if="userInfo && userInfo.roleInfo==0" :to="{ name: 'authorize' }">认证
        <template #icon>
          <van-icon class-prefix="icon-artalliance" name="news" size="30" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="userInfo && userInfo.roleInfo==1" replace :to="{ name: 'userupload' }" >
        <template #icon>
          <div  class="shangchuan"></div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="userInfo && userInfo.roleInfo==2" replace :to="{ name: 'art-list' }">选品
        <template #icon>
          <van-icon class-prefix="icon-artalliance" name="cert" size="30" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace :to="{ name: 'cart' }">购物车
        <template #icon>
          <van-icon  name="cart-o" size="30" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item replace :to="{ name: 'user' }">{{myname}}
        <template #icon>
          <van-icon class-prefix="icon-artalliance" name="me" size="30" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'BasicLayout',

  data(){
    return{
      token:'',
      routename:'user',
      tabname:'加入',
      myname:'我的',
    }
  },
  created() {

  },
  computed: {
  },
  mounted() {
    this.token = Vue.ls.get('ARTALLIANCE_WWW_TOKEN');
    console.log(JSON.stringify(this.userInfo));
    //userInfo.roleInfo  0未认证,1艺术家,2合伙人
    if(!this.userInfo ||!this.userInfo.id){
      try{
        let a=window.localStorage.getItem('userInfo');
        a=JSON.parse(a);
        this.userInfo=a;
      }catch(e){
        console.log('error1:'+e);
      }
    }
    if(!this.token||!this.userInfo){  //默认未登录
      console.log('nologin');
      this.routename='user';
      this.tabname='加入';
      this.myname='我的';
    }else if(this.userInfo.roleInfo==0){ //普通人
      console.log('0');
      this.routename='authorize';
      this.tabname='去认证';
      this.myname='我的';
    }else if(this.userInfo.roleInfo==1){ //艺术家
      console.log('1');
      this.routename='cart';
      this.tabname='藏品';
      this.myname='个人主页';
    }else if(this.userInfo.roleInfo==2){ //合伙人
      console.log('2');
      this.routename='cart';
      this.tabname='藏品';
      this.myname='合伙人';
    }
  }
}
</script>

<style lang="less">

.shangchuan{ width: 64px; height: 64px; background: url("../../public/static/image/shangchuan.png") no-repeat;
  background-size: 100% auto; position:relative; top:-20px; z-index: 10;
}
.l-Basic {

  .top-left--btn,
  .top-right--btn {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    width: 30px;
    height: 30px;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 15px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.10);
    opacity: .88;
    font-size: 20px;

    .van-icon,
    .icon-artalliance {
      color: @primary-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .top-right--btn {
    right: 0;
    margin-left: 0;
    margin-right: 20px;
  }

  .van-tabbar {

    .van-tabbar-item__text {
      font-size: 12px;
    }
  }
}

.square {
  position: relative;

  &-in,
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &-in {
    z-index: 9;
    padding-top: 44px; // backicon
    text-align: center;

    center {
      display: inline-block;
      vertical-align: middle;
    }

    h3 {
      color: #fff;
      font-size: 20px;
      width: percentage(240 / 375);
    }

    blockquote {
      color: #fff;
      font-size: 12px;
      width: percentage(240 / 375);
    }

    .tag-Role {
      margin-bottom: 20px;
    }

    .van-image--round {
      border: 2px solid #fff;
      margin-bottom: 10px;
      background-color: #fff;
    }

    .tag-Certified {
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 0;
    }
  }

  &-bg {

    &:after {
      content: '';
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &:after {
    content: '';
    padding-top: 100%;
    width: 100%;
    display: block;
  }
}

.sticky-header {
  position: relative;
  height: 60px;

  .van-sticky {
    padding: 10px 15px;
    background-color: #fff;

    & > .van-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  center {
    font-size: 16px;
    line-height: 40px;
  }
}
</style>