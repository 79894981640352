<script>
export default {
  functional: true,
  render (h, { props: {
    width = 200,
    height = 180,
    src,
    name
  }, children, parent }) {
    let suffix = ''

    switch (parent.$route.name) {
      case 'index':
      case 'art-list': {
        suffix = '!art_thumb_360'
        break
      }
      default: {
        suffix = '!art_thumb'
      }
    }

    const image = h('van-image', {
      props: {
        width,
        height,
        fit: 'cover',
        src: src ? `${src}${suffix}` : '',
        errorIcon: 'photo-o',
        lazyLoad: true
      }
    })
    const text = [
      h('p', { class: ['t'] },  name),
      h('p', { class: ['van-multi-ellipsis--l2'] }, children)
    ]

    return h('div', {
      class: ['CardArt']
    }, [
      image,
      h('div', text)
    ])
  }
}
</script>

<style lang="less">
.CardArt {
  width: 100%;
  font-size: 0;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;

  .van-image {

    + div {
      padding: 7px 10px 5px;
      background-color: #fff;
    }
  }

  .t {
    color: @gray-8;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 3px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
    height: 20px;
  }

  span {
    color: #aaa;
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
  }
}
</style>