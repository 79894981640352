import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import { constantRouterMap } from '@/config/router.config'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

router.beforeEach((to, from, next) => {
  const {
    meta: { title } = {},
    matched
  } = to

  if (title) {
    document.title = title
  }

  if (matched.some(m => m.meta.authorized)) {
    return store.dispatch('GetInfo', () => {
      next()
    })
  }

  next()
})

export default router
