export const DEFAULT_AVATAR = '/static/image/default_avatar.png'
export const DEFAULT_BACKGROUND = '/static/image/default_background.jpg'

export const OPUS_TYPE = {
  1: '油画',
  2: '国画',
  3: '水彩',
  4: '摄影',
  5: '综合艺术',
  6: '雕塑',
  7: '素描',
  8: '动漫',
  9: '版画',
  10: '插画',
  11: '抽象',
  12: '装置',
  13: '漆画',
  14: '陶瓷',
  15: '衍生品',
  99: '其他'
}

export const HISTORY_COLLENCTION = 'ARTALLIANCE_HISTORY'
export const HISTORY_LIMIT = 10

export const ART_SIZE = {
  '20-40cm': '20,40',
  '40-60cm': '40,60',
  '60-100cm': '60,100',
  '100-200cm': '100,200',
  '200cm以上': '200,-1'
}

export const BIZ_TYPE = {
  art: '艺术资讯',
  topic: '专题活动',
  cooperation: '合作项目'
}

export const DEFAULT_DOCUMENT_TITLE = '不一致选 - 全国青年原创艺术平台'
