<template>
  <div class="l-Login">
    <div class="top-left--btn">
      <van-icon
        v-if="$route.params.componentId === 'sms-login'"
        name="cross"
        size="24"
        @click="goRedirect"
      />
      <van-icon
        v-else
        name="arrow-left"
        size="24"
        @click="goBack"
      />
    </div>
    <div
      class="top-right--text"
      v-if="['login', 'sms-login'].indexOf($route.params.componentId) > -1"
    >
      <a @click="$router.push({ path: '/login/register' })">注册</a>
    </div>
    <div class="wrapper">
      <div>
        <router-view />
      </div>
      <art-widget-logo style="position: absolute; bottom: 50px; left: 0; right: 0;" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  methods: {
    goBack () {
      const componentId = this.$route.params.componentId

      switch (componentId) {
        case 'login': {
          this.$router.push({
            path: '/login/sms-login'
          })
          break;
        }
        case 'register':
        case 'password-reset': {
          this.$router.push({
            path: '/login/login'
          })
          break;
        }
      }
    },
    goRedirect () {
      const redirect = this.$route.query.redirect || '/'

      return this.$router.push(
        window.decodeURIComponent(redirect)
      )
    }
  }
}
</script>

<style lang="less">
.l-Login {
  height: 100vh;

  .top-left--btn {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
    width: 24px;
    height: 24px;

    .van-icon {
      color: @gray-8;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .top-right--text {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
    height: 24px;

    & > a {
      color: @blue;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .wrapper {
    position: relative;
    padding: 50px 40px;
    height: 100%;

    & > div {
      position: relative;
      top: 12%;
    }
  }
}
</style>