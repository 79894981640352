import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueStorage from 'vue-ls'

import ArtOverlay from './overlay'

import 'normalize.css/normalize.css'
import '@/assets/iconfont.less'
import {
  Button, Field, Form, Icon, Row, Col, Swipe, SwipeItem, Tabs, Tab,
  Tabbar, TabbarItem, Image, List, PullRefresh, Search, Sticky,
  Sidebar, SidebarItem, Divider, Pagination, DropdownMenu, DropdownItem,
  SwitchCell, Cell, Switch, Tag, Notify, Uploader, CellGroup, CountDown,
  Overlay, Dialog, ShareSheet, SwipeCell, Skeleton, Empty, Lazyload,
  Radio, RadioGroup, Collapse, CollapseItem
} from 'vant'

// import Tooltip from 'ant-design-vue/es/tooltip'
// import 'ant-design-vue/es/tooltip/style'
import Timeline from 'ant-design-vue/es/timeline'
import 'ant-design-vue/es/timeline/style'
import Checkbox from 'ant-design-vue/es/checkbox'
import 'ant-design-vue/es/checkbox/style'
import Drawer from 'ant-design-vue/es/drawer'
import 'ant-design-vue/es/drawer/style'
import Spin from 'ant-design-vue/es/spin'
import 'ant-design-vue/es/spin/style'
import BackTop from 'ant-design-vue/es/back-top'
import 'ant-design-vue/es/back-top/style'
import Affix from 'ant-design-vue/es/affix'
import 'ant-design-vue/es/affix/style'

import components from '@/components'
import * as directives from '@/directives';

// filters
import './filters'

Vue.config.productionTip = false

Vue.use(ArtOverlay)

Vue.use(Button)
Vue.use(Field)
Vue.use(Form)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Search)
Vue.use(Sticky)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Divider)
Vue.use(Pagination)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(SwitchCell)
Vue.use(Cell)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(Notify)
Vue.use(Uploader)
Vue.use(CellGroup)
Vue.use(CountDown)
Vue.use(Overlay)
Vue.use(Dialog)
Vue.use(ShareSheet)
Vue.use(SwipeCell)
Vue.use(Skeleton)
Vue.use(Empty)
Vue.use(Lazyload, {
  lazyComponent: true
})
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Collapse)
Vue.use(CollapseItem)

// Vue.use(Tooltip)
Vue.use(Timeline)
Vue.use(Checkbox)
Vue.use(Drawer)
Vue.use(Spin)
Vue.use(Affix)

// hack scrolltop
BackTop.methods.scrollToTop = function (e) {
  this.$emit('click', e);
}

Vue.use(BackTop)

for (let key in components) {
  Vue.component('Art' + key, components[key])
}

Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key])
})

Vue.use(VueStorage, {
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
