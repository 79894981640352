<template>
  <div class="p-Login">
    <!-- <transition name="van-fade" mode="out-in"> -->
    <component
      :is="componentId"
      @router-push="routerPush"
      :key="$route.fullPath"
    ></component>
    <!-- </transition> -->
  </div>
</template>

<script>
import utils from '@/utils'

import Login from './Login'
import SmsLogin from './SmsLogin'
import Register from './Register'
import PasswordReset from './PasswordReset'

const LoginComponents = {
  SmsLogin,
  Login,
  Register,
  PasswordReset
}

export const LoginComponentKeys = Object.keys(LoginComponents).map(utils.kebabCase)
export const defaultLoginComponentKey = LoginComponentKeys[0]

export default {
  computed: {
    componentId () {
      const componentId = this.$route.params.componentId

      if (~LoginComponentKeys.indexOf(componentId)) {
        return componentId
      }

      return ''
    }
  },
  methods: {
    routerPush (componentId) {
      const { params, query } = this.$route

      this.$router.push({
        query,
        params: Object.assign({}, params, {
          componentId
        })
      })
    }
  },
  components: LoginComponents
}
</script>

<style lang="less">
.p-Login {

  & > h1 {
    margin-bottom: 0;
  }

  .to {
    color: @blue;
    font-size: 12px;
  }

  .van-form {
    padding-top: 40px;

    .van-cell {
      padding-left: 0;
      padding-right: 0;

      &__value {
        font-size: 16px;

        input {
          letter-spacing: .5px;
        }
      }
    }

    & > .van-button {
      margin-top: 32px;
      margin-bottom: 15px;
    }
  }
  .van-button--normal {
    font-size: 16px;
  }
  input::-webkit-input-placeholder {
    color: @primary-color-grey;
  }
}
</style>