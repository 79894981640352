<template>
  <transition name="van-fade" mode="out-in">
    <div v-if="!codeVisible" key="s1">
      <h1>手机号登录</h1>
      <van-form @submit="onSubmit">
        <art-field-phone v-model="form.mobileNumber" />
        <van-button type="info" block>获取验证码</van-button>
      </van-form>
      <a class="to" @click="$emit('router-push', 'login')">账号密码登录</a>
      <a class="to" style="float:right;" @click="$router.push({ path: '/login/register' })">注册</a>
    </div>
    <div v-else key="s2">
      <h1>输入验证码</h1>
      <transition name="van-fade" mode="out-in">
        <a class="to" style="position: absolute" v-if="maskedVisible">短信验证码已发送至 {{ masked }}</a>
      </transition>
      <van-form @submit="onSubmit">
        <art-field-captcha
          v-model="form.code"
          @start="getCaptcha"
          @end="maskedVisible = false"
          auto-start
        />
        <van-button type="info" :disabled="!agreed" block>验&nbsp;证</van-button>
      </van-form>
      <agreement v-model="agreed" />
    </div>
  </transition>
</template>

<script>
import request from '@/api/request'
import Agreement from './Agreement'

export default {
  data () {
    return {
      form: {
        mobileNumber: '',
        code: ''
      },
      codeVisible: false,
      maskedVisible: false,
      agreed: false
    }
  },
  computed: {
    masked () {
      const phone = String(this.form.mobileNumber)

      return `+86 ${phone.slice(0, 3)}****${phone.slice(7, 11)}`
    }
  },
  methods: {
    fetchCode () {
      // const { mobileNumber } = this.form;

      // request.getCaptchaBeforeSmsLogin({ mobileNumber })
      //   .then(() => {
      //     this.codeVisible = true
      //   })

      this.codeVisible = true
    },
    getCaptcha (start) {
      const { mobileNumber } = this.form;

      request.getCaptchaBeforeSmsLogin({ mobileNumber })
        .then(() => {
          this.$notify({
            type: 'success',
            message: '发送成功'
          })
          this.maskedVisible = true
          start()
        })
    },
    onSubmit (values) {
      if (values.code === undefined) {
        this.fetchCode()
      } else {
        const mobileNumber = this.form.mobileNumber
        const oValues = Object.assign({}, values, { mobileNumber })

        request.smsLogin(oValues).then(() => {
          const redirect = this.$route.query.redirect

          if (redirect) {
            return this.$router.push(
              window.decodeURIComponent(redirect)
            )
          }

          this.$router.push({ name: 'index' })
        })
      }
    }
  },
  components: { Agreement }
}
</script>
