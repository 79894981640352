import { Overlay } from 'vant'
import request from '@/api/request'

let Vue

function install (_Vue) {
  if (
    install.installed &&
    _Vue === Vue
  ) {
    return
  }

  install.installed = true
  Vue = _Vue

  Vue.mixin(
    Vue.config._lifecycleHooks.indexOf('init') > -1
      ? { init }
      : { beforeCreate: init }
  )
}

let instanceProps = {
  zIndex: 99,
  show: false,
  content: undefined
}

let instance

function init () {
  Object.defineProperty(this, '$overlay', {
    enumerable: true,
    get: () => {
      return {
        agreement: async (type) => {
          const { content } = await request.getArgeement({
            aType: type
          })

          if (content !== undefined) {
            instanceProps.content = content
          }

          if (!instance) {
            instance = new Vue({
              el: document.createElement('div'),
              data () {
                // Overlay is functional comp
                return instanceProps
              },
              render (h) {
                const contentVM = h('div', {
                  class: ['wrapper']
                }, [
                  h('div', {
                    directives: [
                      {
                        name: 'filter-html',
                        value: this.content
                      }
                    ]
                  })
                ])

                return h(Vue.extend(Overlay), {
                  props: instanceProps,
                  on: {
                    click: () => {
                      instanceProps.show = false
                    }
                  }
                }, [contentVM])
              }
            })

            document.body.appendChild(instance.$el)
          }

          instanceProps.show = true
        }
      }
    }
  })
}

export default { install }
