<script>
export default {
  functional: true,
  render (h, {
    data: { class: cls },
    props: {
      width = '100%',
      height = 200,
      src,
      name,
      location,
      stamp,
      onClick
    }
  }) {
    const image = h('van-image', {
      props: {
        width,
        height,
        fit: 'cover',
        src: src || '',
        errorIcon: 'photo-o',
        lazyLoad: true
      }
    })
    const text = [
      h('p', { class: ['t'] }, name),
      h('p', [
        h('span', location),
        h('span', { class: 'pull-right' }, stamp)
      ])
    ]
    const propsData = {
      class: ['CardNews', ...cls]
    }

    if (typeof onClick === 'function') {
      propsData.on = {
        click: onClick
      }
    }

    return h('div', propsData, [
      image,
      h('div', { class: ['context'] }, text)
    ])
  }
}
</script>

<style lang="less">
.CardNews {
  width: 100%;
  font-size: 0;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  .context {
    padding: 10px;
    background-color: #fff;
  }

  .t {
    color: @gray-8;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  span {
    color: #B4B4B4;
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
  }
}
</style>