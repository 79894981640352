<template>
  <div class="o-Agreement">
    <a-checkbox @change="$emit('input', !value)" />&nbsp;
    我已同意并阅读 <span @click="$overlay.agreement(1)">用户协议</span> 与 <span @click="$overlay.agreement(2)">服务条款</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
.o-Agreement {
  color: @text-grey;
  font-size: 12px;

  & > span {
    text-decoration: underline;
  }
}
</style>