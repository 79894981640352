import Vue from 'vue'
import axios from 'axios'
import vueRouter from '@/router'
import requestConfig from './index'
import Notify from 'vant/es/notify'
import utils from '@/utils'

const baseURL = '/api'
const bucket = {}

export const API_TOKEN = 'ARTALLIANCE_WWW_TOKEN'

export function getQr (params = {}) {
  return axios.getUri({
    url: baseURL + '/' + requestConfig.others.getQr.url,
    params
  })
}

// 创建 axios 实例
const request = axios.create({
  baseURL,
  timeout: 6e3
})

// TODO
const err = (error) => {
  if (error.response) {
    const data = error.response.data

    if (error.response.status === 403) {
      Notify({
        type: 'danger',
        message: data.message
      })
    }
  }
  return Promise.reject(error)
}

// response interceptor
request.interceptors.response.use((resp) => {
  const data = resp && resp.data || {}
  const { code, msg } = data

  if (code !== undefined && code !== 200) {
    Notify({
      type: 'danger',
      message: msg
    })
  }

  return data
}, err)

const callback = (resolve, reject) => res => {
  switch (res && res.code) {
    case 200: {
      resolve(res.data || {})

      // store token
      // const { token, list, news } = res.data
      const { token } = res.data

      if (token) {
        Vue.ls.set(API_TOKEN, token)
      }

      // polyfill image
      // const includeKeys = ['imgUrl', 'thumbnailUrl']

      // if (Array.isArray(list)) {
      //   list.forEach(o => {
      //     for (let key of includeKeys) {
      //       if (o[key] !== undefined) {
      //         try {
      //           new URL(o[key])
      //         } catch (e) {
      //           o[key] = 'http://placeimg.com/200/200/any'
      //         }
      //       }
      //     }
      //   })
      // }

      // // polyfill data.news
      // if (typeof news === 'object') {
      //   for (let key of includeKeys) {
      //     if (news[key] !== undefined) {
      //       try {
      //         new URL(news[key])
      //       } catch (e) {
      //         news[key] = 'http://placeimg.com/200/200/any'
      //       }
      //     }
      //   }
      // }

      break
    }
    case 1000: {
      Vue.ls.remove(API_TOKEN)
      break;
    }
    case 1001: { // 未登录
      Vue.ls.remove(API_TOKEN)

      const currentRoute = vueRouter.app.$route.fullPath
      const to = { path: '/login/login' }

      if (currentRoute) {
        to.query = {
          redirect: window.encodeURIComponent(currentRoute)
        }
      }

      vueRouter.app.$router.push(to)

      break
    }
    case 1101: { // 被禁用
      Vue.ls.remove(API_TOKEN)

      vueRouter.app.$router.push({ name: 'index' })

      break
    }
    default: {
      reject(res)
    }
  }
}

function walker (obj, integrate) {
  for (const key in obj) {
    const o = obj[key]
    const { url, method, authorized = false } = o

    if (url) {
      const payload = {
        url,
        method,
        headers: {}
      }

      integrate[key] = params => new Promise(function (resolve, reject) {
        // incase vue not ready
        const token = Vue.ls.get(API_TOKEN)
        const paramsKey = method === 'get' ? 'params' : 'data'
  
        if (authorized) {
          if (token) {
            payload.headers.Authorization = token
          } else {
            // redirect to login page
          }
        }

        payload[paramsKey] = params

        return request(payload).then(
          callback(resolve, reject)
        ).catch(reject)
      })
    } else {
      walker(o, integrate)
    }
  }
}

walker(requestConfig, bucket)

const OssCache = {}
const OssCacheExpired = 3
const OssCacheType = [
  ['common', 'getOssKye'],
  ['avatar', 'getAvatarOssKey'],
  ['opus', 'getOpusOssKey'],
  ['feedback', 'getFeedbackOssKey'],
  ['cert', 'getCertOssKey'],
  ['background', 'getBgOssKey']
]

for (const idx in OssCacheType) {
  const [type, requestKey] = OssCacheType[idx]
  
  OssCache[type] = {
    value: {},
    requestKey
  }
}

const upload = (resolve, reject) => (file, ossCache) => {
  const {
    host,
    dir,
    accessid,
    policy,
    signature
  } = ossCache

  const name = utils.fileUuid(file.name)
  let uploadPath = dir + name
  const form = new FormData()

  form.append('name', name)
  form.append('key', uploadPath)
  form.append('policy', policy)
  form.append('OSSAccessKeyId', accessid)
  form.append('success_action_status', 200)
  form.append('signature', signature)
  form.append('file', file)

  if (uploadPath.indexOf('/') !== 0) {
    uploadPath = '/' + uploadPath
  }

  const fileUrl = uploadPath

  request({
    method: 'post',
    url: host,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: form
  })
    .then(() => resolve(fileUrl))
    .catch(reject)
}

bucket.upload = (file, type = 'common') => new Promise(function (resolve, reject) {
  const { value, requestKey } = OssCache[type]

  if (
    value.expire &&
    value.expire < (Date.now() / 1000) + OssCacheExpired
  ) {
    return upload(resolve, reject)(file, OssCache[type])
  }

  return bucket[requestKey]().then(sign => {
    // get signature
    OssCache[type].value = sign
    upload(resolve, reject)(file, OssCache[type].value)
  })
})

export default bucket
