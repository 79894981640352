<template>
  <van-share-sheet
    v-model="rawValue"
    :title="title"
    :options="rawOptions"
    @select="onSelect"
  />
</template>

<script>
import Vue from 'vue'
import { ImagePreview, Toast } from 'vant'
import html2canvas from 'html2canvas'
import copy2clipboard from 'copy-text-to-clipboard'
import utils from '@/utils'

const templates = {
  qq: 'http://connect.qq.com/widget/shareqq/index.html?url={{URL}}&title={{TITLE}}&source={{SOURCE}}&desc={{DESCRIPTION}}&pics={{IMAGE}}&summary="{{SUMMARY}}"',
  weibo: 'https://service.weibo.com/share/share.php?url={{URL}}&title={{TITLE}}&pic={{IMAGE}}&appkey={{WEIBOKEY}}'
}

function makeUrl (name, data) {
  if (!data['SUMMARY']) {
    data['SUMMARY'] = data['DESCRIPTION']
  }

  return templates[name].replace(/\{\{(\w)(\w*)\}\}/g, function (m, fix, key) {
    return window.encodeURIComponent(data[(fix + key).toUpperCase()] || '')
  });
}

export default {
  data () {
    return {
      rawValue: false,
      rawOptions: [],
      toast: null
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '立即分享给好友'
    },
    options: {
      type: Array,
      default: () => [
        // { name: '微信', icon: 'wechat' },
        { name: '复制链接', icon: '/static/image/icon-link.png', copy: true },
        { name: '微博', icon: 'weibo', key: 'weibo' },
        { name: 'QQ', icon: 'qq', key: 'qq' },
      ]
    },
    posterOption: {
      type: Object,
      default: () => {
        return {
          // name: '生成海报',
          // icon: 'link',
          // posterComponent: 'art-poster-art'
          // beforeCreatePoster () {}
        }
      }
    },
    shareOptions: {
      type: Object,
      default: () => ({
        URL: window.location.origin
          + window.location.pathname,
        TITLE: document.title
      })
    }
  },
  watch: {
    value (o) {
      this.rawValue = o
    },
    rawValue (o) {
      this.$emit('input', o)
    }
  },
  methods: {
    onSelect (option) {
      this.$emit('on-select', option)
      this.rawValue = false

      const { key, copy, posterComponent, beforeCreatePoster } = option

      if (key) {
        const shareLink = makeUrl(key, this.shareOptions)

        return utils.openWindow(shareLink)
      }

      if (copy) {
        copy2clipboard(this.shareOptions.URL)

        return Toast.success({
          duration: 2e3,
          message: '已复制'
        })
      }

      if (beforeCreatePoster && posterComponent) {
        if (this.toast) {
          this.toast.clear()
        }

        this.toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '生成海报中',
        });

        beforeCreatePoster().then(props => {
          this.poster(posterComponent, props)
        })
      }
    },
    poster (component, props) {
      const el = document.createElement('div')
      const vm = new Vue({
        el,
        render (h) {
          return h(component, { props })
        }
      })

      vm.$el.style = 'position: absolute; top: -9999px'
      document.body.appendChild(vm.$el)

      const imgList = vm.$el.querySelectorAll('img')
      const imgListState = (new Array(imgList.length)).fill(undefined)

      // make sure img is loaded
      imgList.forEach((img, index) => {
        if (img.complete) {
          imgListState[index] = true
        }
        else {
          img.onload = () => {
            imgListState[index] = true

            if (imgListState.indexOf(undefined) === -1) {
              renderPoster()
            }
          }
        }
      })

      const renderPoster = () => html2canvas(vm.$el, {
        useCORS: true,
        onclone: () => {
          if (this.toast) {
            this.toast = this.toast.clear()
          }
        }
      }).then(canvas => {
        // rm in-canvas element
        vm.$el.parentElement.removeChild(vm.$el)

        const image = new Image()

        image.setAttribute('crossOrigin', 'anonymous')
        image.src = canvas.toDataURL('image/png')

        const imageEl = document.createElement('div')
        const imagePreviewProps = {
          visible: true
        }
        const imagePreviewVM = new Vue({
          el: imageEl,
          data () {
            return imagePreviewProps
          },
          render (h) {
            return h(Vue.extend(ImagePreview.Component), {
              props: {
                className: 'image-preview__poster',
                value: this.visible,
                images: [image.src],
                closeable: true
              },
              nativeOn: {
                click: evt => {
                  if (~evt.target.className.indexOf('close-icon')) {
                    const vm = imagePreviewVM.$children[0]

                    if (vm) {
                      vm.close()

                      imagePreviewVM.$destroy()
                      this.$nextTick(() => {
                        // close and rm imagePreview
                        imagePreviewVM.$el.parentElement.removeChild(imagePreviewVM.$el)
                      })
                    }
                  }
                }
              },
              scopedSlots: {
                index: () => {
                  return h('a', {
                    domProps: {
                      href: image.src,
                      download: props.title + '.png'
                    }
                  }, [
                    h('van-icon', {
                      props: {
                        classPrefix: 'icon-artalliance',
                        name: 'download'
                      }
                    }),
                    h('span', '点击下载或长按保存图片')
                  ])
                }
              }
            })
          }
        })

        document.body.appendChild(imagePreviewVM.$el)
      })
    }
  },
  created () {
    const options = this.options.slice()

    if (this.posterOption.name) {
      options.push(this.posterOption)
    }

    this.rawOptions = options
  }
}
</script>

<style lang="less">
.image-preview__poster {

  .van-image-preview__index {
    top: auto;
    bottom: 35px;

    a {
      color: #fff;
      border: 1px solid rgba(255,255,255,.5);
      padding: 6px 8px;
      border-radius: 4px;
      opacity: .88;
      letter-spacing: -.5px;
    }

    span {
      margin-left: 5px;
    }
  }
}
</style>