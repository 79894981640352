<script>
const roleList = [
  {
    value: 0,
    roleValue: '未认证',
    styleClass: 'grey',
    icon: 'cert'
  },
  {
    value: 3,
    roleValue: '认证审核中',
    styleClass: 'grey',
    icon: 'cert'
  },
  {
    value: 1,
    roleValue: '认证艺术家',
    styleClass: 'orange',
    icon: 'cert'
  },
  {
    value: 2,
    roleValue: '认证合伙人',
    styleClass: 'green',
    icon: 'cert'
  },
  {
    value: 4,
    roleValue: '认证失败',
    styleClass: 'red',
    icon: 'cert'
  }
]

export default {
  functional: true,
  props: {
    type: {
      type: Number,
      default: 0
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  render (h, {
    props: { type, onClick }
  }) {
    const [{
      roleValue,
      styleClass,
      icon
    }] = roleList.filter(
      ({ value }) => value === type
    )

    return h('van-tag', {
      class: ['tag-Role', styleClass],
      props: {
        round: true
      },
      on: {
        click: onClick
      }
    }, [
      h('van-icon', {
        props: {
          classPrefix: 'icon-artalliance',
          name: icon
        }
      }),
      roleValue
    ])
  }
}
</script>

<style lang="less">
.tag-Role {
  font-size: 12px;
  padding: .2em .75em .2em .6em;

  @grey: #9E9E9E;
  @orange: #E7A22B;
  @green: #22A796;
  @red: #CC4E3F;

  &.grey {
    color: @grey;
    // background-color: fade(@grey, 20);
    background-color: #ececec;
  }

  &.orange {
    color: @orange;
    // background-color: fade(@orange, 20);
    background-color: #faebd4;
  }

  &.green {
    color: @green;
    // background-color: fade(@green, 20);
    background-color: #d4edea;
  }

  &.red {
    color: @red;
    // background-color: fade(@red, 10);
    background-color: #feeae4;
  }

  .icon-artalliance {
    font-size: 16px;
  }
}
</style>