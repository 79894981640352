import dayjs from 'dayjs'

require('dayjs/locale/zh-cn')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.locale('zh-cn')
dayjs.extend(relativeTime)

export { dayjs }

export function fromNow (stamp) {
  const dayjsObj = dayjs(stamp)

  if (dayjsObj.isValid()) {
    return dayjsObj.fromNow()
  }

  return ''
}
