const modules = require.context('.', true, /\/.*\.(vue|js)$/)
const o = {}

modules.keys().forEach(key => {
  const moduleName = key.split('/').reduce((name, chars) => {
    const str = chars.split('.')[0]

    if (str.length) {
      return name + str.charAt(0).toUpperCase() + str.slice(1)
    }

    return name
  }, '')

  if (moduleName && modules(key).default) {
    o[moduleName] = modules(key).default
  }
})

export default o
