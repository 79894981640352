<template>
  <div class="PosterArt">
    <div class="wrapper">
      <!-- <div :style="{
        backgroundImage: `url('${imgUrl}!posters_source')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        paddingTop: '100%', // 320 / 375
        height: 0
      }"></div> -->
      <img width="100%" :src="`${imgUrlComputed}?t=${Date.now()}`" crossOrigin="anonymous" />
    </div>
    <div class="footer">
      <!-- <art-widget-logo /> -->
      <div class="left">
        <h3>{{ title }}</h3>
        <p>{{ subTitle }}</p>
      </div>
      <div class="right">
        <!-- <p>识别二维码<br>看作品详情</p> -->
        <span class="frame">
          <img :src="qrUrl" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'imgUrl',
    'title',
    'subTitle',
    'qrUrl'
  ],
  computed: {
    imgUrlComputed () {
      if (typeof this.imgUrl === 'string') {
        return this.imgUrl.replace('https://oss.resource','http://share') + '!posters_source'
      }

      return this.imgUrl
    }
  }
}
</script>

<style lang="less">
@logo-width: 124px;
@qr-width: 60px;

.PosterArt {
  width: 375px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 5px;

  .wrapper {

    img {
      display: block;
    }
  }

  .footer {
    padding: 20px 15px 5px;
    position: relative;

    .left {
      display: inline-block;

      h3 {
        color: #030303;
        font-size: 18px;
        margin-bottom: 5px;
      }

      p {
        color: #B4B4B4;
        font-size: 14px;
        max-width: 260px;
      }
    }

    center {
      font-size: 0;
      width: @logo-width;
      display: inline-block;
      vertical-align: middle;
    }

    .right {
      // width: calc(100% - @logo-width);
      display: inline-block;
      vertical-align: top;
      position: absolute;
      right: 15px;
      top: 20px;

      p {
        color: #C9C9C9;
        font-size: 12px;
        line-height: 18px;
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - @qr-width);
        padding-right: 20px;
        margin-bottom: 0;
      }

      .frame {
        width: 60px;
        height: 60px;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:after {
      content: ""; 
      display: block; 
      clear: both; 
    }
  }
}
</style>