<template>
  <div class="p-Resume">
    <div class="header">
      <van-icon name="arrow-left" size="20" @click="$router.push({ name: 'user' })" />
      <center>我的履历</center>
      <div class="top-right--text">
        <a @click="save">保存</a>
      </div>
    </div>
    <div class="block">
      <h3>个人简介</h3>
      <van-field
        v-model="form.introduction"
        rows="4"
        type="textarea"
        maxlength="120"
        placeholder="请输入您的签名"
        show-word-limit
      />
    </div>
    <div class="block">
      <h3>展览经历
        <span>({{ displayHistory.count }}/{{ displayHistory.max }})</span>
        <van-icon class="pull-right" name="plus" @click="addDisplay" />
      </h3>
      <art-widget-close-input-group
        v-model="form.displayHistory"
        :max-count="displayHistory.max"
        @input="(...args) => displayHistory.count = args[1]"
        ref="input"
      />
    </div>
    <div class="block">
      <h3>获奖证书</h3>
      <art-widget-file-list
        v-model="fileList"
        oss-type="cert"
        :max-count="6"
      />
    </div>
    <div class="block">
      <h3>出版经历
        <span>({{ publishHistory.count }}/{{ publishHistory.max }})</span>
        <van-icon class="pull-right" name="plus" @click="addPublish" />
      </h3>
      <art-widget-close-file-group
        v-model="form.publishHistory"
        :max-count="publishHistory.max"
        @input="(...args) => publishHistory.count = args[1]"
        ref="file"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/api/request'
import utils from '@/utils'

export const RESUME_GROUP_DELIMITER = '<br><br>'
export const RESUME_DELIMITER = '<br/>'

export default {
  data () {
    return {
      form: {
        introduction: '',
        displayHistory: '',
        // certificateHistory: '',
        publishHistory: ''
      },
      displayHistory: {
        max: 12,
        count: 1
      },
      certificateHistory: {
        max: 6,
        count: 0
      },
      publishHistory: {
        max: 4,
        count: 1
      },
      fileList: [],
      delimiter: RESUME_GROUP_DELIMITER
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {
    userInfo: 'init'
  },
  methods: {
    init (userInfo = this.userInfo) {
      Object.keys(this.form).forEach(key => {
        if (userInfo[key]) {
          this.form[key] = userInfo[key]
        }
      })
      if (userInfo.certificateHistory !== undefined) {
        const fileList = []
        const certificateHistory = userInfo.certificateHistory || ''

        certificateHistory.split(this.delimiter).forEach(s => {
          if (utils.trim(s) !== '') {
            fileList.push(s)
          }
        })

        this.fileList = fileList
      }
    },
    save () {
      const form = utils.cloneDeep(this.form)
      const values = Object.assign({}, form, {
        certificateHistory: this.fileList.join(this.delimiter)
      });

      request.updateUser(values).then(() => {
        this.$notify({
          type: 'success',
          message: '保存成功'
        })
        this.$store.dispatch('$GetInfo')
        this.$router.push({
          name: 'user'
        })
      })
    },
    addDisplay () {
      this.$refs.input.add()
    },
    addPublish () {
      this.$refs.file.add()
    },
    afterRead () {}
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less">
.p-Resume {
  min-height: 100vh;
  background-color: #F7F8FA;
  padding-bottom: @tabbar-height;

  .header {
     margin-bottom: 8px;
  }

  .block {
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 8px;

    .van-cell {
      padding: 10px 0;
    }

    h3 .van-icon {
      color: #9E9E9E;
      font-size: 14px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-top: 2px;
      background-color: #F7F8FA;
      border-radius: 500px;
    }
  }
}
</style>