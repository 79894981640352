import Vue from 'vue'
import Vuex from 'vuex'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

import request from '@/api/request'
import user from './modules/user'

Vue.use(Vuex)

const TAG_TYPE_LIST = {
  designType: 1,
  sceneType: 2,
  subjectType: 3,
  commissionType: 4,
  colorType: 5
}

export default new Vuex.Store({
  modules: {
    user
  },
  state: {
    tags: {}
  },
  getters: {
    tags: state => state.tags
  },
  mutations: {
    SET_TAGS: (state, tags) => {
      state.tags = tags
    }
  },
  actions: {
    GetTags ({ commit, state }, callback) {
      if (!isEmpty(state.tags)) {
        if (typeof callback === 'function') {
          callback(state.tags)
        }

        return
      }

      return request.getTags().then(res => {
        res = res.tagList || []

        const tagTypeListMap = {}

        for (const key in TAG_TYPE_LIST) {
          tagTypeListMap[TAG_TYPE_LIST[key]] = key
        }

        const tags = {}

        for (const o in res) {
          const {
            tagName,
            tagOrder,
            tagType,
            tagValue
          } = res[o]

          const key = tagTypeListMap[tagType]

          if (key !== undefined) {
            // initial value
            tags[key] = tags[key] || { options: [] }

            const option = {
              key: tagName,
              order: tagOrder
            }

            if (tagValue !== undefined && tagValue !== '') {
              option.value = tagValue
            }

            tags[key].options.push(option)
          }
        }

        for (const key in tags) {
          const tag = tags[key]
          // desc by orderValue
          const orderOptions = sortBy(tag.options, o => -o.order)

          tag.options = orderOptions.map(o => o.key)
        }

        commit('SET_TAGS', tags)

        if (typeof callback === 'function') {
          callback(tags)
        }
      })
    }
  }
})
