<script>
import {
  RESUME_GROUP_DELIMITER,
  RESUME_DELIMITER
} from '@/views/user/id/Resume'

export const CloseFile = {
  data () {
    return {
      delimiter: RESUME_DELIMITER
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  render (h) {
    const [text, image] = this.value.split(this.delimiter)
    const Input = h('div', {
      class: ['input']
    }, [
      h('van-field', {
        props: {
          value: text,
          placeholder: '出版经历描述',
          type: 'textarea',
          autosize: true,
          rules: [
            { required: true, message: '不可为空' }
          ]
        },
        on: {
          input: _value => this.$emit(
            'input', [_value, image].join(this.delimiter)
          )
        }
      })
    ])
    const Image = h('div', {
      class: ['file']
    }, [
      h('art-widget-avatar', {
        props: {
          width: 80,
          height: 80,
          round: false,
          ossType: 'cert',
          value: image
        },
        on: {
          input: _value => this.$emit(
            'input', [text, _value].join(this.delimiter)
          )
        }
      })
    ])
    const Close = h('van-icon', {
      class: ['close'],
      props: { name: 'cross' },
      on: {
        click: () => this.$emit('close')
      }
    })

    return h('div', {
      class: ['closeFile'],
    }, [Image, Input, Close])
  }
}

export const CloseFileGroup = {
  data () {
    return {
      delimiter: RESUME_GROUP_DELIMITER
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    maxCount: {
      type: [Number, String],
      default: 4
    }
  },
  methods: {
    add () {
      const rows = this.value.split(this.delimiter)

      if (rows.length < Number(this.maxCount)) {
        this.$emit('input',
          this.value + this.delimiter,
          rows.length + 1
        )
      }
    }
  },
  components: { CloseFile },
  render (h) {
    const childrenValues = this.value.split(this.delimiter)

    return h('div', {
      class: ['closeFileGroup']
    }, childrenValues.map(
      (value, index) => h('close-file', {
        props: {
          value
        },
        on: {
          input: _value => {
            const oValues = childrenValues.slice()

            oValues[index] = _value
            this.$emit('input',
              oValues.join(this.delimiter),
              childrenValues.length
            )
          },
          close: () => {
            const oValues = childrenValues.slice()

            oValues.splice(index, 1)
            this.$emit('input',
              oValues.join(this.delimiter),
              childrenValues.length
            )
          }
        }
      })
    ))
  }
}

export default CloseFileGroup

</script>

<style lang="less">
.closeFileGroup {

  .closeFile {
    display: flex;
    align-items: flex-start;
    position: relative;

    .input {
      flex: 1;
      margin-left: 16px;
      min-height: 80px;
      margin-right: 35px;

      .van-cell {
        padding: 0;
        flex: 1;

        &:after {
          content: initial;
        }
      }
    }

    .close {
      color: #9E9E9E;
      font-size: 14px;
      line-height: 20px;
      margin-left: 15px;
      width: 20px;
      height: 20px;
      text-align: center;
      background-color: #F7F8FA;
      border-radius: 500px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
</style>